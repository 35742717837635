<template>
  <div>
    <h1>URG-RH</h1>
    <h2>Réinitialiser le mot de passe</h2>
    <el-form
      ref="formResetpw"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
      :hide-required-asterisk="true"
    >
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="Nouveau mot de passe" prop="password">
        <el-input ref="password" v-model="form.password" show-password />
      </el-form-item>
      <el-form-item
        label="Confirmez le mot de passe"
        prop="password_confirmation"
      >
        <el-input v-model="form.password_confirmation" show-password />
      </el-form-item>
      <el-button
        type="primary"
        native-type="submit"
        @click.prevent="resetPassword"
        >Envoyer</el-button
      >
    </el-form>
    <p>
      <router-link to="/login">Login >></router-link>
    </p>
  </div>
</template>

<script>
import { getUserError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ResetPassword",
  data() {
    const valideEmail = (rule, value, callback) => {
      if (value) {
        const valStr = value.toString().trim();
        if (!valStr) {
          callback(new Error("Ce champ est obligatoire"));
        } else if (
          /^[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(valStr)
        ) {
          callback();
        } else {
          callback(new Error("l'adresse email n'est pas valide"));
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    const validePw = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Ce champ est obligatoire"));
      } else if (value.toString().length < 8) {
        callback(new Error("Le mot de passe doit avoir au moins 8 caractères"));
      } else {
        callback();
      }
    };
    const valideConfirmpw = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Ce champ est obligatoire"));
      } else if (value.toString() != this.form.password.toString()) {
        callback(
          new Error("La confirmation ne correspond pas au mot de passe.")
        );
      } else {
        callback();
      }
    };
    return {
      form: {
        email: this.$route.query.email,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      error: null,
      message: null,
      rules: {
        email: [{ required: true, validator: valideEmail, trigger: "blur" }],
        password: [
          {
            required: true,
            whitespace: false,
            validator: validePw,
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            whitespace: false,
            validator: valideConfirmpw,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.$refs.password.focus();
  },
  methods: {
    resetPassword() {
      this.$refs.formResetpw.validate(async (valid) => {
        if (valid) {
          this.error = null;
          this.message = null;
          const payload = this.form;
          AuthService.resetPassword(payload)
            .then(() => {
              this.message = "Le mot de passe a été réinitialisé.";
              this.$router.push("login" + "?email=" + this.form.email);
            })
            .catch((error) => (this.error = getUserError(error)));
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.$message.error(this.error);
      }
    },
    message() {
      if (this.message) {
        this.$message({
          message: this.message,
          type: "success",
        });
      }
    },
  },
};
</script>
